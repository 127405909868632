<template>
  <div id="app">
    <a-layout id="components-layout-demo-custom-trigger" v-if="$route.name != 'Login' && $route.name != 'Home' && $route.name != 'Page Not Found' && $route.name != 'Signup'">
      <a-layout-sider theme="light"
        width="250"
        breakpoint="lg" v-model:collapsed="collapsed" collapsible  v-if="profile">
        <div class="logo">
          <img :src="clientLogo" alt="" />
        </div>
        <div class="main-menu" @refreshSideMenu="updateMenu">
          <a-menu theme="light" v-model:selectedKeys="selectedKeys" mode="inline">
            <a-menu-item key="1" v-if="profile.profile_type.slug === 'agency'">
              <router-link to="/dashboard">
              <img src="./assets/images/home.svg" />
              <span class="menu-title">
                Dashboard
              </span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="1" v-if="profile.profile_type.slug === 'client' || profile.profile_type.slug === 'system_users'">
              <router-link :to="'/client/dashboard' + loginAs">
              <img src="./assets/images/home.svg" />
              <span class="menu-title">
                Dashboard
              </span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="2" v-if="profile.profile_type.slug === 'agency'">
              <router-link to="/category">
              <img src="./assets/images/category.png" />
              <span class="menu-title">
                Categories
              </span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="3" v-if="profile.profile_type.slug === 'agency'">
              <router-link to="/course">
              <img src="./assets/images/course.png" />
              <span class="menu-title">
                Courses
              </span>
              </router-link>
            </a-menu-item>
            <!--<a-sub-menu key="sub1" v-if="profile.profile_type.slug === 'agency'">
              <template #title>
                <span>
                  <img src="./assets/images/users.png" />
                  <span class="menu-title">User Management</span>
                </span>
              </template>
              <a-menu-item key="4">
                <router-link to="/users">
                <span class="menu-title">
                  Users
                </span>
                </router-link>
              </a-menu-item>
              <a-menu-item key="5">
                <router-link to="/users/privilege">
                <span class="menu-title">
                  Privileges
                </span>
                </router-link>
              </a-menu-item>
              <a-menu-item key="6">
                <router-link to="/users/roles">
                <span class="menu-title">
                  Roles
                </span>
                </router-link>
              </a-menu-item>
            </a-sub-menu>-->
            <a-menu-item key="7" v-if="profile.profile_type.slug === 'agency'">
              <router-link to="/manage-payments">
              <img src="./assets/images/payment.png" />
              <span class="menu-title">
                Manage Payment
              </span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="8" v-if="profile.profile_type.slug === 'agency'">
              <router-link to="/clients">
              <img src="./assets/images/client.png" />
              <span class="menu-title">
                Client
              </span>
              </router-link>
            </a-menu-item>
            <!--<a-menu-item key="9" v-if="profile.profile_type.slug === 'agency'">
              <router-link to="/candidates">
              <img src="./assets/images/users.png" />
              <span class="menu-title">
                Candidates
              </span>
              </router-link>
            </a-menu-item>-->
            <a-sub-menu key="sub2" v-if="profile.profile_type.slug === 'agency'">
              <template #title>
                <span>
                  <img src="./assets/images/settings.png" />
                  <span class="menu-title">Settings</span>
                </span>
              </template>
              <a-menu-item key="11">
                <router-link to="/keys">
                <span class="menu-title">
                  Payment Settings
                </span>
                </router-link>
              </a-menu-item>
              <a-menu-item key="12">
                <router-link to="/sys-settings">
                <span class="menu-title">
                  System Settings
                </span>
                </router-link>
              </a-menu-item>
              <a-menu-item key="29">
                <router-link to="/change-password">
                <span class="menu-title">
                  Change Password
                </span>
                </router-link>
              </a-menu-item>
            </a-sub-menu>
            <a-menu-item key="25" v-if="profile.profile_type.slug === 'client' || profile.profile_type.slug === 'system_users'">
              <router-link :to="'/client/candidates' + loginAs">
                <img src="./assets/images/users.png" />
                <span class="menu-title">
                  Candidates
                </span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="14" v-if="profile.profile_type.slug === 'client' || profile.profile_type.slug === 'system_users'">
              <router-link :to="'/client/courses' + loginAs">
                <img src="./assets/images/course.png" />
                <span class="menu-title">
                  Courses
                </span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="16" v-if="profile.profile_type.slug === 'client' || profile.profile_type.slug === 'system_users'">
              <router-link :to="'/client/certificate' + loginAs">
              <img src="./assets/images/file-text.svg" />
              <span class="menu-title">
                Certificate
              </span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="17" v-if="profile.profile_type.slug === 'client' || profile.profile_type.slug === 'system_users'">
              <router-link :to="'/client/matrix' + loginAs">
                <img src="./assets/images/clipboard.svg" />
                <span class="menu-title">
                  Matrix
                </span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="22" v-if="profile.profile_type.slug === 'client' || profile.profile_type.slug === 'system_users'">
              <router-link :to="'/client/subscription' + loginAs">
                <img src="./assets/images/clientCredit.svg" />
                <span class="menu-title">
                  Subscription
                </span>
              </router-link>
            </a-menu-item>
            <!-- <a-menu-item key="23" v-if="profile.profile_type.slug === 'client'">
              <router-link to="/client/settings/personal-details">
              <img src="./assets/images/settings.png" />
              <span class="menu-title">
                Settings
              </span>
              </router-link>
            </a-menu-item> -->
            <a-sub-menu key="sub5" v-if="profile.profile_type.slug === 'client' || profile.profile_type.slug === 'system_users'">
              <template #title>
                <span>
                  <img src="./assets/images/settings.png" />
                  <span class="menu-title">Settings</span>
                </span>
              </template>
              <a-menu-item key="20">
                <router-link :to="'/client/settings/personal-details' + loginAs">
                <span class="menu-title">
                  Personal Details
                </span>
                </router-link>
              </a-menu-item>
              <a-menu-item key="26">
                <router-link :to="'/client/settings/change-password' + loginAs">
                <span class="menu-title">
                  Change Password
                </span>
                </router-link>
              </a-menu-item>
              <a-menu-item key="27">
                <router-link :to="'/client/settings/emails' + loginAs">
                <span class="menu-title">
                  Emails
                </span>
                </router-link>
              </a-menu-item>
              <a-menu-item key="28">
                <router-link :to="'/client/settings/apps' + loginAs">
                <span class="menu-title">
                  App Settings
                </span>
                </router-link>
              </a-menu-item>
            </a-sub-menu>
            <a-menu-item key="18" v-if="profile.profile_type.slug === 'candidate'">
              <router-link :to="'/candidate/course' + loginAs">
              <img src="./assets/images/course.png" />
              <span class="menu-title">
                Course
              </span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="19" v-if="profile.profile_type.slug === 'candidate' && profile.certificate_access">
              <router-link :to="'/candidate/certificate' + loginAs">
              <img src="./assets/images/file-text.svg" />
              <span class="menu-title">
                Certificate
              </span>
              </router-link>
            </a-menu-item>
            <a-sub-menu key="sub4" v-if="profile.profile_type.slug === 'candidate'">
              <template #title>
                <span>
                  <img src="./assets/images/settings.png" />
                  <span class="menu-title">Settings</span>
                </span>
              </template>
              <a-menu-item key="20">
                <router-link :to="'/candidate/settings/personal-details' + loginAs">
                <span class="menu-title">
                  Personal Details
                </span>
                </router-link>
              </a-menu-item>
              <a-menu-item key="24">
                <router-link :to="'/candidate/settings/change-password' + loginAs">
                <span class="menu-title">
                  Change Password
                </span>
                </router-link>
              </a-menu-item>
              <a-menu-item key="21">
                <router-link :to="'/candidate/settings/help-center' + loginAs">
                <span class="menu-title">
                  Help Center
                </span>
                </router-link>
              </a-menu-item>
            </a-sub-menu>
          </a-menu>
        </div>
      </a-layout-sider>
      <a-layout>
        <a-layout-header style="background: #fff; padding: 0">
          <div class="site-header">
            <a-page-header :title="$route.name">
              <template v-slot:extra>
                <a-menu mode="horizontal">
                  <!--<a-menu-item key="16">
                    <NotificationOutlined />
                  </a-menu-item>
                  <a-menu-item key="17">
                    <SearchOutlined />
                  </a-menu-item>-->
                  <a-sub-menu key="sub4" v-if="profile">
                    <template #title>
                      {{profile.first_name}} {{profile.last_name}}
                      <span class="submenu-title-wrapper" v-if="profile.profile_image">
                        <a-avatar :src="profile.profile_image"/>
                      </span>
                    </template>
                    <a-menu-item key="19" @click="logout"> Sign Out </a-menu-item>
                  </a-sub-menu>
                </a-menu>
              </template>
            </a-page-header>
          </div>
        </a-layout-header>
        <a-layout-content
          :style="{
            padding: '24px',
            minHeight: '90vh'
          }"
        >
          <router-view @updateMenu="updateMenu" />
        </a-layout-content>
      </a-layout>
    </a-layout>
    <div v-if="$route.name == 'Login' || $route.name == 'Home' || $route.name == 'Page Not Found' || $route.name == 'Signup'">
      <router-view @updateMenu="updateMenu" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { NotificationOutlined, SearchOutlined } from '@ant-design/icons-vue'

import authServices from './services/auth'
import commonServices from './services/common'
import router from './router'
import IProfile from './interface/profile'

export default defineComponent({
  setup () {
    const loginAs = ref(new URLSearchParams(window.location.search).get('loginAs'))
    const profile = ref<IProfile>(commonServices.getCurrentProfile())
    const clientLogo = ref<string>(localStorage.getItem('clientLogo'))
    const logout = () => {
      authServices.logout()
      router.push('/login')
    }
    const updateMenu = () => {
      profile.value = commonServices.getCurrentProfile()
      clientLogo.value = localStorage.getItem('clientLogo')
      console.log('clientLogo.value', clientLogo.value)
    }
    onMounted(() => {
      if (loginAs.value) {
        loginAs.value = '?loginAs=' + loginAs.value
      } else {
        loginAs.value = ''
      }
    })
    return {
      selectedKeys: ref(['1']),
      collapsed: ref(false),
      logout,
      profile,
      updateMenu,
      clientLogo,
      loginAs
    }
  },
  components: {
    // NotificationOutlined,
    // SearchOutlined
  }
})
</script>
<style lang="scss">
@font-face {
  font-family: "TT Norms Pro Regular";
  font-style: normal;
  font-weight: normal;
  src: local("TT Norms Pro Regular"),
    url("./assets/fonts/TT Norms Pro Regular.woff") format("woff");
}

@font-face {
  font-family: "TT Norms Pro Italic";
  font-style: normal;
  font-weight: normal;
  src: local("TT Norms Pro Italic"),
    url("./assets/fonts/TT Norms Pro Italic.woff") format("woff");
}

@font-face {
  font-family: "TT Norms Pro Thin";
  font-style: normal;
  font-weight: normal;
  src: local("TT Norms Pro Thin"), url("./assets/fonts/TT Norms Pro Thin.woff") format("woff");
}

@font-face {
  font-family: "TT Norms Pro Thin Italic";
  font-style: normal;
  font-weight: normal;
  src: local("TT Norms Pro Thin Italic"),
    url("./assets/fonts/TT Norms Pro Thin Italic.woff") format("woff");
}

@font-face {
  font-family: "TT Norms Pro ExtraLight";
  font-style: normal;
  font-weight: normal;
  src: local("TT Norms Pro ExtraLight"),
    url("./assets/fonts/TT Norms Pro ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "TT Norms Pro ExtraLight Italic";
  font-style: normal;
  font-weight: normal;
  src: local("TT Norms Pro ExtraLight Italic"),
    url("./assets/fonts/TT Norms Pro ExtraLight Italic.woff") format("woff");
}

@font-face {
  font-family: "TT Norms Pro Light";
  font-style: normal;
  font-weight: normal;
  src: local("TT Norms Pro Light"),
    url("./assets/fonts/TT Norms Pro Light.woff") format("woff");
}

@font-face {
  font-family: "TT Norms Pro Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("TT Norms Pro Light Italic"),
    url("./assets/fonts/TT Norms Pro Light Italic.woff") format("woff");
}

@font-face {
  font-family: "TT Norms Pro Medium";
  font-style: normal;
  font-weight: normal;
  src: local("TT Norms Pro Medium"),
    url("./assets/fonts/TT Norms Pro Medium.woff") format("woff");
}

@font-face {
  font-family: "TT Norms Pro Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("TT Norms Pro Medium Italic"),
    url("./assets/fonts/TT Norms Pro Medium Italic.woff") format("woff");
}

@font-face {
  font-family: "TT Norms Pro Bold";
  font-style: normal;
  font-weight: normal;
  src: local("TT Norms Pro Bold"), url("./assets/fonts/TT Norms Pro Bold.woff") format("woff");
}

@font-face {
  font-family: "TT Norms Pro Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("TT Norms Pro Bold Italic"),
    url("./assets/fonts/TT Norms Pro Bold Italic.woff") format("woff");
}

@font-face {
  font-family: "TT Norms Pro ExtraBold";
  font-style: normal;
  font-weight: normal;
  src: local("TT Norms Pro ExtraBold"),
    url("./assets/fonts/TT Norms Pro ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "TT Norms Pro ExtraBold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("TT Norms Pro ExtraBold Italic"),
    url("./assets/fonts/TT Norms Pro ExtraBold Italic.woff") format("woff");
}

@font-face {
  font-family: "TT Norms Pro Black";
  font-style: normal;
  font-weight: normal;
  src: local("TT Norms Pro Black"),
    url("./assets/fonts/TT Norms Pro Black.woff") format("woff");
}

@font-face {
  font-family: "TT Norms Pro Black Italic";
  font-style: normal;
  font-weight: normal;
  src: local("TT Norms Pro Black Italic"),
    url("./assets/fonts/TT Norms Pro Black Italic.woff") format("woff");
}

@font-face {
  font-family: "TT Norms Pro ExtraBlack";
  font-style: normal;
  font-weight: normal;
  src: local("TT Norms Pro ExtraBlack"),
    url("./assets/fonts/TT Norms Pro ExtraBlack.woff") format("woff");
}

@font-face {
  font-family: "TT Norms Pro ExtraBlack Italic";
  font-style: normal;
  font-weight: normal;
  src: local("TT Norms Pro ExtraBlack Italic"),
    url("./assets/fonts/TT Norms Pro ExtraBlack Italic.woff") format("woff");
}
#app {
  font-family: "TT Norms Pro Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#components-layout-demo-custom-trigger {
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  .trigger:hover {
    color: #1890ff;
  }
  .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
    overflow: hidden;
    img {
      height: 100%;
    }
  }
  .ant-layout-sider-collapsed {
    .logo {
      img {
        height: 100%;
      }
    }
    .menu-title {
      display: none;
    }
  }
}
.content-body {
  .ant-breadcrumb {
    text-align: left;
  }
}
.main-menu {
  .ant-btn {
    margin-bottom: 16px;
    box-shadow: none;
    background-color: transparent;
    border-color: transparent;
    color: #656565;
    font-size: 20px;
    float: left;
    margin-left: 10px;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    background-color: transparent;
    border-color: transparent;
    color: #656565;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #f7fafc 0% 0% no-repeat padding-box;
  }
  .ant-menu-inline > .ant-menu-item{
    height: 50px;
    line-height: 50px;
  }
  .ant-menu {
    .ant-menu-item,
    .ant-menu-submenu {
      text-align: left;
      img {
        width: 15px;
        margin-right: 10px;
      }
      .menu-title {
        font-family: "TT Norms Pro Regular";
        a {
          color: #707070;
        }
        .router-link-active {
          color: #38B6FF;
          font-family: "TT Norms Pro Medium";
        }
      }
    }
  }
}
.ant-empty {
  background: #ffffff;
  height: 200px;
  border-radius: 15px;
}
// client > candidate course list side drawer start
.courselistDrawer {
  .ant-drawer-title {
    font-family: "TT Norms Pro Medium";
    color: #646464;
  }
  .ant-drawer-close {
    .anticon {
      color: #f6596f;
      border: 1px solid #f6596f;
      border-radius: 50%;
      padding: 5px;
    }
  }
  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      border-radius: 50%;
    }
    .ant-checkbox-inner::after {
      border-radius: 50%;
      background-color: #38B6FF;
    }
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #38B6FF;
    border-color: #38B6FF;
  }
  .ant-checkbox-inner {
      border-radius: 50%;
    }
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    .ant-checkbox-group-item {
      margin-right: 0;
      margin: 5px 0;
      color: #646464;
      font-family: "TT Norms Pro Regular";
      text-transform: capitalize;
    }

  }
  .footersec {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;
    text-align: right;
    z-index: 1;
    .ant-btn-primary {
      display: block;
      width: 150px;
      margin: 0 auto;
      background-color: #72cf97;
      border-color: #72cf97;
      font-family: "TT Norms Pro Medium";
      text-shadow: none;
    }
  }
}
.link {
  cursor: pointer;
  color: #38B6FF;
}
// client > candidate course list side drawer end

//client common delete modal start
.delte-confirm-modal {
  .ant-modal-confirm-body {
    font-family: "TT Norms Pro Regular";
    & > .anticon {
      float: none;
      display: block;
      color: #f6596f;
      font-size: 50px;
      margin: 20px 0 0 0;
    }
    .ant-modal-confirm-title {
      text-align: center;
      margin: 25px 0;
      font-size: 30px;
      color: #646464;
    }
    .ant-modal-confirm-content {
      color: #929292;
      font-size: 16px;
      margin: 0 !important;
      text-align: center;
    }
  }
  .ant-modal-confirm-btns {
    text-align: center;
    float: none !important;
    margin-bottom: 24px;
    .ant-btn {
      background-color: #d1d1d1;
      color: #fff;
      font-family: "TT Norms Pro Regular";
      width: 100px;
      &.ant-btn-danger {
        background-color: #f6596f;
        &:hover, &:focus{
           border-color: #f6596f;
        }
      }
    }
    .ant-btn:hover,
    .ant-btn:focus {
      border-color: #d1d1d1;
    }
  }
}
//client common delete modal end
 input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// common media quries
@media only screen and (max-width: 992px) {
  .ant-layout.ant-layout-has-sider{
    position: relative;
  }
  .ant-layout-sider{
    &.ant-layout-sider-light{
      box-shadow: 0px 5px 10px #0000000f;
      position: absolute;
      height: 100%;
      z-index: 99;
    }
  }
  .ant-layout-sider-light + .ant-layout{
        padding: 0 0 0 80px;
  }
}
@media only screen and (max-width: 768px) {
  .course-body-content {
    .renderContent {
      img {
        width: 100% !important;
        height: auto !important;
      }
    }
  }
}
body{
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #305299;
  }
}
</style>
